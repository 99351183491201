<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-28 20:35:08
 * @LastEditTime: 2023-07-04 14:16
 * @Descripttion: 试题库【试题】
-->
<style lang="scss" scoped>
    .examination {
        @include innerPage(28px 32px 48px 15px);
        @include pageTitle(0);
        @include pageTab;
        @include pageFoot;

        &-container {
            width: 100%;
            height: calc(100% - 123px);
            box-sizing: border-box;
            padding-top: 34px;
        }

        &-head {
            height: 44px;
            margin-bottom: 42px;
            @include flexBox(space-between);

            .paper-type {
                height: 42px;
                @include flexBox;

                li {
                    width: 104px;
                    height: 100%;

                    position: relative;
                    margin-right: 15px;
                    cursor: pointer;

                    p {
                        width: 100%;
                        height: 100%;
                        color: #3d3d3d;
                        border-radius: 10px;
                        background: #fff;
                        text-align: center;
                        line-height: 42px;
                        position: relative;
                        z-index: 2;
                    }

                    &::before {
                        content: attr(text);
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        color: #fff;
                        background: #6340c8;
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        transform: scale(0);
                        z-index: 3;
                        text-align: center;
                        line-height: 40px;
                        border: 1px solid #6340c8;
                        box-sizing: border-box;
                        @include defaultAni;
                    }

                    &::after {
                        content: '';
                        border: solid transparent;
                        border-width: 8px 8px 0 8px;
                        border-top-color: #6340c8;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        opacity: 0;
                        transform: translateX(-50%);
                        z-index: 1;
                        @include defaultAni;
                    }

                    &.current,
                    &:not(.current):hover {
                        p {
                            color: transparent;
                        }

                        &::before {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }

                    &:not(.current):hover {
                        p {
                            color: transparent;
                        }

                        &::before {
                            opacity: 1;
                            background: #f0edfa;
                            color: #8971d5;
                            border-color: #8971d5;
                            transform: scale(1);
                        }
                    }

                    &.current::after {
                        bottom: -4px;
                        opacity: 1;
                    }
                }
            }
        }

        &-inner {
            width: calc(100% + 36px);
            height: calc(100% - 74px);
            margin-left: -36px;
            box-sizing: border-box;
            padding-bottom: 30px;
            position: relative;

            &.no-data {
                width: 100%;
                height: calc(100% - 104px);
                border-radius: 10px;
                background: #fff;
                margin-bottom: 30px;
                margin-left: 0;
            }

            &.paper {
                width: calc(100% + 24px);
                height: calc(100% - 160px);
                margin-left: -24px;

                &.no-data {
                    height: calc(100% - 190px);
                    margin-left: 0;
                }
            }

            .paper-list {
                width: calc(100% + 20px);
                height: 100%;
                overflow: hidden;
                overflow-y: auto;
                flex-wrap: wrap;
                @include flexBox(flex-start, flex-start);

                .paper-item {
                    width: calc((100% - 72px) / 3);
                    height: 266px;
                    border-radius: 10px;
                    background: #fff;
                    margin-left: 24px;
                    margin-bottom: 28px;
                    overflow: hidden;

                    &.is-mine {
                        h4::after {
                            content: '';
                            width: 62px;
                            height: 30px;
                            line-height: 30px;
                            box-sizing: border-box;
                            padding-left: 10px;
                            border-radius: 0 0 0 20px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            font-family: 'MicrosoftYaHei';
                            font-size: 14px;
                            color: #fff;
                            background: #a8a8a8;
                            transform: scale(0);
                        }

                        &.reviewed h4:after {
                            content: '待审核';
                            background: #feaf2f;
                            transform: scale(1);
                        }

                        &.failed h4:after {
                            content: '未通过';
                            background: #ee4f65;
                            transform: scale(1);
                        }
                    }

                    h4 {
                        width: 100%;
                        height: 60px;
                        background: #f6f7fc;
                        box-sizing: border-box;
                        padding-left: 58px;
                        padding-right: 40px;
                        color: #474747;
                        flex-shrink: 0;
                        position: relative;
                        @include flexBox;

                        span {
                            display: inline-block;
                            width: 100%;
                        }

                        &::before {
                            content: '\e631';
                            font-family: 'iconfont';
                            font-size: 30px;
                            color: #d0cbe7;
                            position: absolute;
                            left: 18px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .info-content {
                        width: 100%;
                        height: 146px;
                        @include flexBox;

                        .q_number,
                        .s_number {
                            width: 50%;
                            text-align: center;
                            font-size: 16px;
                            color: #2a2a2a;
                            line-height: 36px;
                            position: relative;

                            .pf_bold {
                                font-size: 24px;
                                color: #393939;
                            }
                        }

                        .s_number::before {
                            content: '';
                            width: 1px;
                            height: 14px;
                            background: #a3a3a3;
                            position: absolute;
                            bottom: 12px;
                            left: 0;
                        }
                    }

                    .info-bt {
                        width: calc(100% - 76px);
                        height: 58px;
                        margin: 0 auto;
                        border-top: 1px solid #e9e9e9;
                        @include flexBox(space-between);

                        .el-button {
                            padding: 0 10px;

                            &--text {
                                padding: 0;

                                &.is-disabled {
                                    cursor: default;
                                }

                                &:not(.is-disabled):hover .paper-info {
                                    color: #6c4bcb;
                                }
                            }
                        }

                        .paper-info {
                            color: #696969;
                            margin-right: auto;
                            @include flexBox;

                            .iconfont {
                                font-size: 18px;
                            }

                            .from {
                                margin-right: 8px;
                            }

                            &.shared {
                                color: #6c4bcb;
                                cursor: default;
                            }
                        }

                        .del {
                            margin-left: 10px;
                            font-size: 16px;
                        }

                        .share-person {
                            color: #aeaeae;
                            margin-left: auto;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.24), 5px 5px 27px -10px rgba(108, 78, 203, 0.24);
                    }
                }
            }

            .questions {
                width: calc(100% + 20px);
                height: 100%;
                overflow: hidden;
                overflow-y: auto;
                flex-wrap: wrap;
                @include flexBox(flex-start, flex-start);

                &-item {
                    width: calc((100% - 108px) / 3);
                    height: 318px;
                    margin-left: 36px;
                    background: #fff;
                    border-radius: 10px;
                    overflow: hidden;
                    margin-bottom: 32px;

                    h4 {
                        width: 100%;
                        height: 60px;
                        background: #f6f7fc;
                        box-sizing: border-box;
                        padding-left: 58px;
                        padding-right: 40px;
                        color: #474747;
                        flex-shrink: 0;
                        position: relative;
                        @include flexBox;

                        span {
                            display: inline-block;
                            width: 100%;
                        }

                        &::before {
                            content: '\e62f';
                            font-family: 'iconfont';
                            font-size: 30px;
                            color: #d0cbe7;
                            position: absolute;
                            left: 18px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .detail-enter {
                        width: 100%;
                        height: 198px;
                        padding: 30px 0;
                        position: relative;
                        @include flexBox(space-around);

                        &::after {
                            content: '';
                            width: 1px;
                            height: 120px;
                            background: #f5f5f5;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        .school,
                        .terrace {
                            width: 168px;
                            height: 100%;
                            background: url("~@/assets/images/bg_school.png");
                            text-align: center;
                            box-sizing: border-box;
                            line-height: 24px;
                            padding-top: 52px;

                            .number {
                                font-size: 24px;
                                line-height: 36px;
                                color: #2ac293;
                            }

                            .tip {
                                color: #2a2a2a;
                                margin-bottom: 30px;
                            }

                            .enter-link {
                                color: #2c2c2c;

                                &:hover {
                                    color: #2ac293;
                                    cursor: pointer;
                                }
                            }
                        }

                        .terrace {
                            background: url("~@/assets/images/bg_terrace.png");

                            .number {
                                color: #faaa1e;
                            }

                            .enter-link {
                                &:hover {
                                    color: #faaa1e;
                                }
                            }
                        }
                    }

                    &:hover {

                        box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.24), 5px 5px 27px -10px rgba(108, 78, 203, 0.24);
                    }
                }
            }
        }
    }
</style>

<template>
    <section class="examination">
        <div class="page-title">
            <h3>试题库</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-select v-model="searchForm.grade" placeholder="选择年级" clearable @clear="searchData">
                            <el-option v-for="item in gradeList" :key="item.grade_id" :label="item.grade_name"
                                       :value="item.grade_id"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="this.tabIndex == 1">
                        <el-select v-model="searchForm.semester" placeholder="请选择学期" clearable>
                            <el-option label="上学期" :value="1"/>
                            <el-option label="下学期" :value="2"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.sysub_id" placeholder="选择科目" clearable @clear="searchData"
                                   @change="getTextBooks">
                            <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                       :value="item.sysub_id"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="this.tabIndex == 0">
                        <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="输入试卷名称" clearable
                                  @clear="searchData"/>
                    </el-form-item>
                    <el-form-item v-if="this.tabIndex == 1">
                        <el-select v-model="searchForm.sccou_id" placeholder="请选择教材版本" filterable clearable
                                   @clear="searchData">
                            <el-option v-for="item in textbooks" :key="item.sccou_id" :label="item.sccou_version_name"
                                       :value="item.sccou_id"/>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" size="medium" @click="searchData">搜索</el-button>
            </div>
        </div>
        <!-- tab标签导航 -->
        <div class="page-tab">
            <ul class="tabs bold">
                <li class="tab" :class="{ current: tabIndex == index }" v-for="(item, index) in tabs" :key="index"
                    @click="changeData(index)">
                    {{ item }}
                </li>
            </ul>
        </div>
        <div class="examination-container">
            <div class="examination-head" v-if="tabIndex == 0">
                <ul class="paper-type">
                    <li :class="{ current: paperType == 1 }" @click="changePaper(1)" text="校本试卷">
                        <p>校本试卷</p>
                    </li>
                    <li :class="{ current: paperType == 2 }" @click="changePaper(2)" text="学校共享试卷">
                        <p>学校共享试卷</p>
                    </li>
                </ul>
                <el-button type="custom_primary" size="medium" v-if="paperType == 1"
                           @click="$router.push({ name: 'RESOURCES_EXAMINATION_PAPER_APPEND' })">
                    新增试卷+
                </el-button>
            </div>
            <div class="examination-inner" :class="{ paper: tabIndex == 0, 'no-data': $isEmpty(listData) }"
                 v-loading="dataLoad">
                <template v-if="!$isEmpty(listData)">
                    <ul class="paper-list" v-if="tabIndex == 0">
                        <li class="paper-item" v-for="item in listData" :key="item[`${elPrefix}pap_id`]"
                            :class="[item.plpap_type == 10 && 'is-mine', paperShare(item[`${elPrefix}pap_isshare`])]"
                            @click="targetDetail(item[`${elPrefix}pap_id`])">
                            <h4 class="bold">
                                <span class="line-text--1st">{{ item[`${elPrefix}pap_title`] }}</span>
                            </h4>
                            <div class="info-content">
                                <div class="q_number">
                                    <p class="pf_bold">{{ item[`${elPrefix}pap_sumquestion`] || 0 }}</p>
                                    <p>共计题目数（题）</p>
                                </div>
                                <div class="s_number">
                                    <p class="pf_bold">{{ item[`${elPrefix}pap_sumscore`] || 0 }}</p>
                                    <p>总分（分）</p>
                                </div>
                            </div>
                            <div class="info-bt">
                                <template v-if="item.plpap_type == 10 && item[`${elPrefix}pap_isshare`] > 15">
                                    <el-button type="text" :disabled="item[`${elPrefix}pap_isshare`] != 30"
                                               @click.stop="sharePaper(item[`${elPrefix}pap_id`])">
                                        <p class="paper-info">
                                            <i class="iconfont">&#xe639;</i>
                                            <span>
                                                {{ item[`${elPrefix}pap_isshare`] == 30 && '重新共享' ||
                                                    item[`${elPrefix}pap_isshare`] == 20 && '已共享' }}
                                            </span>
                                        </p>
                                    </el-button>
                                </template>
                                <template v-if="item.plpap_type == 10 && item[`${elPrefix}pap_isshare`] == 10">
                                    <el-button type="text" @click.stop="sharePaper(item[`${elPrefix}pap_id`])">
                                        <p class="paper-info">
                                            <i class="iconfont">&#xe639;</i>
                                            <span>未共享</span>
                                        </p>
                                    </el-button>
                                </template>
                                <template v-if="paperType == 2">
                                    <el-button type="custom_primary" size="small" :disabled="item.is_addmypaper == 1"
                                               @click.stop="joinMine(item[`${elPrefix}pap_id`])">
                                        {{ item.is_addmypaper == 1 && '已加入' || '+ 加入学校' }}
                                    </el-button>
                                </template>
                                <p class="share-person">
                                    共享人：{{ item.teuse_name || '-' }}
                                </p>
                                <el-button
                                        v-if="item.plpap_type == 10 && (item[`${elPrefix}pap_isshare`] == 10 || item[`${elPrefix}pap_isshare`] == 30)"
                                        class="del iconfont mini" type="custom_danger" is-plain circle
                                        @click.stop="deleteExam(item[`${elPrefix}pap_id`])">
                                    &#xe620;
                                </el-button>
                            </div>
                        </li>
                    </ul>
                    <ul class="questions" v-if="tabIndex == 1">
                        <li class="questions-item" v-for="item in listData" :key="item.sccou_id">
                            <h4 class="bold">
                                <span class="line-text--1st">{{ item.question_bank_name }}</span>
                            </h4>
                            <div class="detail-enter">
                                <div class="school">
                                    <p class="pf_bold number">{{ item.plque_ban_schoolnum || 0 }}</p>
                                    <p class="tip">校本库共计题目数</p>
                                    <p class="enter-link" @click="targetQuestions(1, item.sccou_id)">
                                        进入&gt;
                                    </p>
                                </div>
                                <div class="terrace">
                                    <p class="pf_bold number">{{ item.plque_ban_platformnum || 0 }}</p>
                                    <p class="tip">平台库共计题目数</p>
                                    <p class="enter-link" @click="targetQuestions(2, item.sccou_id)">
                                        进入&gt;
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </template>
                <div class="no-data--empty" v-if="$isEmpty(listData) && !dataLoad">
                    <img src="@assets/images/no-data3.png" alt="">
                    <p>暂无{{ tabIndex == 0 && '试卷' || '题库' }}资源哦~</p>
                </div>
            </div>
            <!-- 分页控件 -->
            <div class="custom-foot">
                <customPagination :current="listPage.pageIndex" :total="listPage.total" :size="listPage.pageSize"
                                  @pageChange="flippingPage"/>
            </div>
        </div>
    </section>
</template>

<script>
    import {$exampaperList, $shareExampaper, $delExampaper, $exampaperInsert, $getQuesGroup} from "@api/resources"
    import {$getSubTextbook} from "@api/common"
    import customPagination from "@comp/customPagination";
    import {mapState} from "vuex"

    export default {
        name: 'resources_examination',
        components: {customPagination},
        computed: {
            ...mapState("common", ["subjectList", 'gradeList']),
            paperShare() {
                return function (val) {
                    let className = '';
                    switch (val) {
                        case 15:
                            className = 'reviewed'
                            break;
                        case 30:
                            className = 'failed'
                            break;
                        default:
                            break;
                    }
                    return className;
                }
            }
        },
        data() {
            return {
                searchForm: {},
                textbooks: [],
                tabs: ['试卷', '题库'],
                tabIndex: 0,
                paperType: 1, // 试卷类型 1. 校本   2. 平台
                listData: [], // 考试列表数据
                /* 表格分页参数 */
                listPage: {
                    pageIndex: 1,
                    pageSize: 9,
                    total: 0,
                },
                elPrefix: 'sc', // 参数前缀
                dataLoad: false,
            }
        },
        created() {
            this.getData();
        },
        methods: {
            /** 获取数据 */
            async getData() {
                this.dataLoad = true;
                let params = {...this.searchForm, pageindex: this.listPage.pageIndex}
                let res;
                switch (this.tabIndex) {
                    case 1:
                        res = await this.getQuesGroup(params); // 获取题库列表
                        break;
                    default:
                        res = await this.getExamPapers(params); // 获取试卷列表
                        break;
                }
                ;
                this.dataLoad = false;
                this.listData = res.data;
                this.listPage.total = res.allcount;
            },
            /** 获取题库列表 */
            async getQuesGroup(params) {
                let {data: res} = await $getQuesGroup(params, this.listPage.pageSize);
                return res;
            },
            /** 获取试卷列表 */
            async getExamPapers(params) {
                if (this.paperType == 1) {
                    params = {
                        ...params,
                        scpap_examine: 20,
                        scpap_status: 10
                    }
                }
                let {data: res} = await $exampaperList(this.paperType, params, this.listPage.pageSize);
                return res;
            },
            /** 获取教材版本列表 */
            async getTextBooks(id) {
                if (!id) {
                    this.textbooks = [];
                    delete this.searchForm.sccou_id;
                    return;
                }
                let {data} = await $getSubTextbook(id);
                this.textbooks = [...data]
            },
            /** 翻页 */
            flippingPage(val) {
                this.listData = [];
                this.listPage.pageIndex = val;
                this.getData();
            },
            /**
             * 切换数据类型
             * @param {number} index 类型下标 0. 试卷   1. 题库
             */
            changeData(index) {
                this.tabIndex = index;
                this.searchData();
            },
            /**
             * 切换试卷类型
             * @param {number} val 类型值 1. 校本   2. 平台
             */
            changePaper(val) {
                this.paperType = val;
                this.elPrefix = val == 1 && 'sc' || 'pl';
                this.searchData();
            },
            /** 筛选数据 */
            searchData() {
                if (!this.searchForm.sysub_id) {
                    this.textbooks = [];
                    delete this.searchForm.sccou_id;
                }
                this.listData = [];
                this.listPage.pageIndex = 1;
                this.getData();
            },
            /**
             * 共享试卷
             * @param {number} id 试卷id
             */
            sharePaper(id) {
                this.$msgbox({
                    title: "提示",
                    message: "确认将选中试卷共享至平台？",
                    type: "info",
                    showClose: false,
                    showCancelButton: true,
                    cancelButtonClass: "el-button--custom_info",
                    confirmButtonClass: "el-button--custom_primary",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(async () => {
                    let res = await $shareExampaper(id);
                    if (res) {
                        this.$message.success('试卷共享申请已提交，等待平台审核');
                        this.getData();
                    }
                }).catch(() => {
                    this.$message({
                        type: "info",
                        duration: 1500,
                        message: "操作已取消",
                    });
                });
            },
            /**
             * 加入我的试卷
             * @param {number} id 试卷id
             */
            joinMine(id) {
                this.$msgbox({
                    title: "提示",
                    message: "确认将选中试卷加入校本试卷库？",
                    type: "info",
                    showClose: false,
                    showCancelButton: true,
                    cancelButtonClass: "el-button--custom_info",
                    confirmButtonClass: "el-button--custom_primary",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(async () => {
                    let res = await $exampaperInsert(id);
                    if (res) {
                        this.$message.success('试卷已成功加入校本试卷库');
                        this.getData();
                    }
                }).catch(() => {
                    this.$message({
                        type: "info",
                        duration: 1500,
                        message: "操作已取消",
                    });
                });
            },
            /**
             * 删除试卷
             * @param {number} id 试卷id
             */
            deleteExam(id) {
                this.$msgbox({
                    title: "删除",
                    message: "确认将选中试卷删除？",
                    type: "warning",
                    showClose: false,
                    showCancelButton: true,
                    cancelButtonClass: "el-button--custom_info",
                    confirmButtonClass: "el-button--custom_primary",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(async () => {
                    let res = await $delExampaper(id);
                    if (res) {
                        this.$message.success('试卷已成功删除');
                        this.listPage.total = this.listPage.total - 1 < 0 && 0 || this.listPage.total - 1;
                        if (this.listData.length == 1 && this.listPage.pageIndex != 1) {
                            this.listPage.pageIndex = this.listPage.pageIndex - 1;
                        }
                        this.getData();
                    }
                }).catch(() => {
                    this.$message({
                        type: "info",
                        duration: 1500,
                        message: "操作已取消",
                    });
                });
            },
            /**
             * 跳转试卷详情
             * @param {number} 试卷id
             */
            targetDetail(id) {
                this.$router.push({
                    name: 'RESOURCES_EXAMINATION_PAPER',
                    params: {id, type: this.paperType}
                })
            },
            /**
             * 跳转题库列表
             * @param {number} type 题目列表类型 1.学校  2.平台
             * @param {number} id 题库id（教材版本id）
             */
            targetQuestions(type, id) {
                this.$router.push({
                    name: "RESOURCES_EXAMINATION_QUESTIONS",
                    params: {type, id},
                });
            },
        },
    }
</script>